import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  signal,
  untracked,
  type WritableSignal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslatePipe } from '@ngx-translate/core';
import { addSeconds, differenceInSeconds } from 'date-fns';
import { NgxMaskDirective } from 'ngx-mask';
import { catchError, finalize, interval, map, of, startWith, take, tap } from 'rxjs';

import { AuthService } from '@clover/auth/auth.service';
import { ToastrService } from '@clover/core/services/toastr.service';
import { FormErrorMessagesComponent } from '@core/components/form-error-messages/form-error-messages.component';
import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { ButtonLoadingDirective } from '@core/directives/button-disable.directive';
import { FocusableDirective } from '@core/directives/focusable.directive';

export enum TfaProviderType {
  Email = 'Email',
}

export interface TfaLoginPayload {
  providerType: TfaProviderType;
  destination: string;
  token: string;
  cooldownInSeconds: number;
  createdAt: string;
}

export interface TfaLoginResult {
  code: string;
  token: string;
}

@UntilDestroy()
@Component({
  selector: 'app-tfa-modal',
  templateUrl: './tfa-modal.component.html',
  styleUrls: ['./tfa-modal.component.scss'],
  imports: [
    FocusableDirective,
    AssetSrcDirective,
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    FormErrorMessagesComponent,
    ButtonLoadingDirective,
    TranslatePipe,
    NgxMaskDirective,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TfaModalComponent {
  payload: WritableSignal<TfaLoginPayload>;

  protected readonly tfaForm = new FormGroup({
    code: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]),
  });

  protected readonly isLoading = signal(false);

  private readonly currentTime = toSignal<Date>(
    interval(1000).pipe(
      startWith(new Date()),
      map(() => new Date()),
    ),
  );

  readonly canResendInSeconds = computed(() => {
    const currentTime = this.currentTime();
    const payload = this.payload();

    return untracked(() => {
      const resendTime = addSeconds(new Date(payload.createdAt), payload.cooldownInSeconds);
      const difference = differenceInSeconds(resendTime, currentTime);

      return Math.max(difference, 0);
    });
  });

  readonly activeModal = inject(NgbActiveModal);

  private readonly authService = inject(AuthService);
  private readonly toastr = inject(ToastrService);

  submitCode(): void {
    if (this.tfaForm.invalid) return;

    this.isLoading.set(true);

    this.authService
      .confirmCode({
        code: this.tfaForm.value.code,
        token: this.payload().token,
      })
      .pipe(
        take(1),
        tap((result) => this.activeModal.close(result)),
        catchError((errorResponse) => {
          this.isLoading.set(false);
          this.toastr.error('tfaModal.messages.failedToConfirmCode');
          return of(errorResponse);
        }),
      )
      .subscribe();
  }

  resendCode(): void {
    if (this.canResendInSeconds() > 0) return;

    this.isLoading.set(true);

    this.authService
      .resendCode(this.payload().token)
      .pipe(
        take(1),
        tap((payload) => this.payload.set(payload)),
        tap(() => this.tfaForm.reset()),
        tap(() => this.toastr.success('tfaModal.messages.resendCodeSuccess')),
        catchError((errorResponse) => {
          this.toastr.error('tfaModal.messages.failedToResendCode');
          return of(errorResponse);
        }),
        finalize(() => this.isLoading.set(false)),
      )
      .subscribe();
  }
}
