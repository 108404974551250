import { Component } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';

import { AssetSrcDirective } from '@core/directives/asset-src.directive';

@Component({
  selector: 'app-invitation-expired',
  templateUrl: './invitation-expired.component.html',
  styleUrls: ['./invitation-expired.component.scss'],
  imports: [AssetSrcDirective, TranslatePipe],
})
export class InvitationExpiredComponent {}
