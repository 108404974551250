import { ChangeDetectionStrategy, Component, Input, output } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';

import { MobileInterstitialType } from '@core/components/mobile-interstitial/mobile-interstitial';
import { ButtonComponent } from '@design/buttons/button/button.component';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';

@Component({
  selector: 'cc-mobile-interstitial',
  templateUrl: './mobile-interstitial.component.html',
  styleUrls: ['./mobile-interstitial.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslatePipe, ButtonComponent],
})
export class MobileInterstitialComponent {
  @Input()
  type: Exclude<MobileInterstitialType, MobileInterstitialType.None> = MobileInterstitialType.SmallWindow;

  dismiss = output<void>();

  protected readonly ButtonType = ButtonType;
  protected readonly ButtonSize = ButtonSize;
  protected readonly MobileInterstitialType = MobileInterstitialType;
}
