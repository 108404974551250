import { inject } from '@angular/core';
import { ResolveFn, Router } from '@angular/router';
import { catchError, defer, map, of } from 'rxjs';

import { WINDOW } from '@clover/core/helpers/global-objects';

import { AuthService } from '../auth.service';
import { getSSORedirectUrl } from './redirect-url';

export const ssoBypassResolver: ResolveFn<boolean> = (route) => {
  const { domain, email } = route.queryParams;
  const forceSelectAccount = route.queryParams['forceSelectAccount'] === 'true';

  const router = inject(Router);

  // If both domain and email are provided, or neither are provided, navigate to login
  if ((!domain && !email) || (domain && email)) {
    router.navigate(['/', 'login']);
    return false;
  }

  const authService = inject(AuthService);
  const window = inject(WINDOW);

  const redirectUrl = getSSORedirectUrl(window.location.origin);

  return defer(() => {
    if (domain) return authService.signInWithSSODomain(domain, redirectUrl, forceSelectAccount);
    if (email) return authService.signInWithSSOEmail(email, redirectUrl, forceSelectAccount);
  }).pipe(
    map(({ redirectUrl }) => {
      window.location.replace(redirectUrl);
      return true;
    }),
    catchError(() => {
      router.navigate(['/', 'login']);
      return of(false);
    }),
  );
};
