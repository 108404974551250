import { ChangeDetectionStrategy, Component, signal, inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslatePipe } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { derivedAsync } from 'ngxtension/derived-async';
import { map, of, defer, tap, catchError } from 'rxjs';
import { v4 as uuid } from 'uuid';

import { InitNewEmailComposer } from '@clover/conversations-v4/composer/state/composers/composers.actions';
import { CONVERSATIONS_BASE_URL } from '@clover/conversations-v4/routes';
import { ExternalAccountStatus } from '@clover/conversations-v4/workspaces/state/workspaces/workspaces-state.model';
import type { ExternalAccount } from '@clover/conversations-v4/workspaces/state/workspaces/workspaces-state.model';
import { WorkspacesService } from '@clover/conversations-v4/workspaces/state/workspaces/workspaces.service';
import type { LoadingStatus } from '@clover/core/helpers/loading';
import type { UserPermissions } from '@clover/core/services/permission.service';
import { UserService } from '@clover/core/services/user.service';
import { RouterSelectors } from '@clover/custom-router-state-serializer';
import { TaskAssignmentService } from '@clover/tasks-v2/task-assignment/task-assignment.service';
import { ButtonComponent } from '@design/buttons/button/button.component';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { DropdownActionComponent } from '@design/overlays/dropdown/dropdown-action/dropdown-action.component';
import { DropdownTextComponent } from '@design/overlays/dropdown/dropdown-text/dropdown-text.component';
import { DropdownTriggerDirective } from '@design/overlays/dropdown/dropdown-trigger.directive';
import { DropdownComponent } from '@design/overlays/dropdown/dropdown.component';

@Component({
  selector: 'cc-header-create-button',
  imports: [
    ButtonComponent,
    TranslatePipe,
    DropdownTriggerDirective,
    DropdownComponent,
    DropdownActionComponent,
    DropdownTextComponent,
  ],
  templateUrl: './header-create-button.component.html',
  styleUrl: './header-create-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderCreateButtonComponent {
  protected readonly ButtonType = ButtonType;
  protected readonly ButtonSize = ButtonSize;

  private readonly taskAssignmentService = inject(TaskAssignmentService);
  private readonly workspacesService = inject(WorkspacesService);
  private readonly router = inject(Router);
  private readonly store = inject(Store);
  private readonly userService = inject(UserService);

  get permissions(): UserPermissions {
    return this.userService.permissions;
  }

  protected readonly externalAccountsLoadingStatus = signal<LoadingStatus>('void');
  protected readonly externalAccounts = derivedAsync(() => {
    return defer(() => {
      if (!this.permissions.ConversationV2_Read) throw new Error('User does not have ConversationV2_Read permission');

      this.externalAccountsLoadingStatus.set('loading');
      return this.workspacesService.getWorkspacesAndExternalAccounts();
    }).pipe(
      map(({ externalAccounts }) => externalAccounts),
      map((accounts) => accounts.filter((account) => account.status === ExternalAccountStatus.Active)),
      tap(() => this.externalAccountsLoadingStatus.set('loaded')),
      catchError(() => {
        this.externalAccountsLoadingStatus.set('error');
        return of(undefined);
      }),
    );
  });

  createNewTask(): void {
    this.taskAssignmentService.startTaskAssignmentFlow();
  }

  async createNewEmail(account: ExternalAccount): Promise<void> {
    const [{ path: currentModulePath }] = this.store.selectSnapshot(RouterSelectors.segments);

    if (currentModulePath !== CONVERSATIONS_BASE_URL)
      await this.router.navigate(['/', CONVERSATIONS_BASE_URL, account.workspaceId]);

    this.store.dispatch(new InitNewEmailComposer(uuid(), account.workspaceId, account.id));
  }
}
