import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { NgbDropdown, NgbDropdownMenu, NgbDropdownToggle } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { filter, map, startWith, takeUntil } from 'rxjs/operators';

import { AUDIT_TRAIL_BASE_URL } from '@clover/audit-trail/routes';
import { BPI_BASE_URL } from '@clover/bpi/routes';
import { CAMPAIGNS_BASE_URL } from '@clover/campaigns-v2/routes';
import { DASHBOARD_BASE_URL } from '@clover/dashboard-v2/routes';
import { NETWORK_BASE_URL } from '@clover/network-v2/routes';
import { PRODUCTS_BASE_URL } from '@clover/products-v2/routes';
import { PO_BASE_URL } from '@clover/purchase-orders/routes';
import { TASKS_BASE_URL } from '@clover/tasks-v2/routes';
import { WORKFLOWS_BASE_URL } from '@clover/workflows-v2/routes';
import { CONVERSATIONS_BASE_URL } from '@conversations/routes';
import { CompanyLogoComponent } from '@core/components/company-logo/company-logo.component';
import { GlobalSearchComponent } from '@core/components/global-search/global-search.component';
import { NotificationsDropdownMenuComponent } from '@core/components/notifications-dropdown-menu/notifications-dropdown-menu.component';
import { ProfileDropdownMenuComponent } from '@core/components/profile-dropdown-menu/profile-dropdown-menu.component';
import { UserLogoComponent } from '@core/components/user-logo/user-logo.component';
import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { FocusableDirective } from '@core/directives/focusable.directive';
import { ResizeDirective, ResizeEvent } from '@core/directives/resize.directive';

import { HeaderCreateButtonComponent } from './header-create-button/header-create-button.component';
import { ConnectedUser, User } from '../../models/user';
import { UserPermissions } from '../../services/permission.service';
import { UserService } from '../../services/user.service';

enum HeaderSide {
  Left = 'left',
  Right = 'right',
}

const ALTERNATIVE_HEADER_ROUTES = [
  `/${DASHBOARD_BASE_URL}`,
  `/${NETWORK_BASE_URL}`,
  `/${CONVERSATIONS_BASE_URL}`,
  `/${TASKS_BASE_URL}/list`,
  `/${TASKS_BASE_URL}/assign`,
  `/${CAMPAIGNS_BASE_URL}/list`,
  `/${WORKFLOWS_BASE_URL}/list`,
  `/${PRODUCTS_BASE_URL}/list`,
  `/${PO_BASE_URL}/list`,
  `/${AUDIT_TRAIL_BASE_URL}`,
  `/${BPI_BASE_URL}`,
  '/task-info',
] as const;

declare global {
  interface Window {
    Beacon: any;
  }
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ResizeDirective,
    AssetSrcDirective,
    GlobalSearchComponent,
    CompanyLogoComponent,
    NgbDropdownToggle,
    FocusableDirective,
    NgbDropdown,
    RouterLink,
    NotificationsDropdownMenuComponent,
    NgbDropdownMenu,
    ProfileDropdownMenuComponent,
    UserLogoComponent,
    HeaderCreateButtonComponent,
  ],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild('notificationDropdown')
  notificationDropdown: any;

  @Input()
  showLogo = false;

  forceAlternativeHeader = input(false);

  user: User;
  currentConnectedUser: ConnectedUser | undefined;
  hasNotifications = false;

  rightSideWidth = 0;
  leftSideWidth = 0;
  sideWidth = 0;

  protected readonly HeaderSide = HeaderSide;
  private isBeaconExpanded = false;
  private destroyed$: Subject<void> = new Subject<void>();

  private readonly userService = inject(UserService);
  private readonly router = inject(Router);
  private readonly zone = inject(NgZone);

  protected readonly activatedUrl = toSignal(
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map((event: NavigationEnd) => event.url),
      startWith(this.router.url),
    ),
  );

  protected readonly useAlternativeHeader = computed(() => {
    if (this.forceAlternativeHeader()) return true;
    return ALTERNATIVE_HEADER_ROUTES.some((route) => this.activatedUrl()?.includes(route));
  });

  constructor() {
    this.zone.runOutsideAngular(() => {
      document.addEventListener('click', this.isClickOutsideBeacon.bind(this), true);
    });
  }

  get permissions(): UserPermissions {
    return this.userService.permissions;
  }

  ngOnInit(): void {
    this.userService.profile$.pipe(takeUntil(this.destroyed$)).subscribe((user) => {
      if (!user) {
        return;
      }
      this.user = this.userService.isSwitchedProfile ? this.userService.mainUserData.userProfile : user;
    });
  }

  notificationsToggle(): void {
    this.notificationDropdown.toggle();
  }

  beaconToggle(): void {
    window.Beacon('toggle');
    this.isBeaconExpanded = !this.isBeaconExpanded;
  }

  setHasNotifications(hasNotifications: boolean): void {
    this.hasNotifications = hasNotifications;
  }

  setCurrentConnectedUser(user: ConnectedUser | undefined): void {
    this.currentConnectedUser = user;
  }

  headerSideResize(event: ResizeEvent, side: HeaderSide): void {
    const width = event.newRect.width;
    if (side === HeaderSide.Left) {
      this.leftSideWidth = width;
    } else {
      this.rightSideWidth = width;
    }
    this.sideWidth = Math.max(this.leftSideWidth, this.rightSideWidth);
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    window.Beacon('close');
    document.removeEventListener('click', this.isClickOutsideBeacon.bind(this), true);
  }

  private isClickOutsideBeacon(e: MouseEvent): boolean {
    if (this.isBeaconExpanded) {
      const target = e.target as HTMLElement;
      if (target.classList.contains('icon-help')) {
        return;
      }
      if (!target.closest('.beacon-container') && !target.closest('.beacon-button')) {
        window.Beacon('close');
        this.isBeaconExpanded = false;
      }
    }
  }
}
