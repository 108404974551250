<div class="nav-logo">
  <img
    [appAssetSrc]="'assets/svg/common/clover-logo.svg'"
    alt="logo"
  />
</div>

<div class="nav-pages">
  @if (permissions?.['Dashboard_NavMenuView']) {
    <a
      #dashboardActive="routerLinkActive"
      [appFocusable]
      [ccTooltip]="'nav.dashboard' | translate"
      [class.active]="dashboardActive.isActive"
      [routerLink]="['/dashboard-old']"
      [tooltipArrow]="false"
      [tooltipOffset]="4"
      [tooltipPosition]="TooltipPosition.Right"
      [tooltipSize]="TooltipPadding.Large"
      class="nav-page-link"
      routerLinkActive
    >
      <i
        [class.icon-home-fill]="dashboardActive.isActive"
        [class.icon-home]="!dashboardActive.isActive"
        class="icon"
      ></i>
    </a>
  }

  @if (permissions?.['Dashboard_NavMenuViewV2']) {
    <a
      #dashboardV2Active="routerLinkActive"
      [appFocusable]
      [ccTooltip]="'nav.dashboard-v2' | translate"
      [class.active]="dashboardV2Active.isActive"
      [routerLink]="['/dashboard']"
      [tooltipArrow]="false"
      [tooltipOffset]="4"
      [tooltipPosition]="TooltipPosition.Right"
      [tooltipSize]="TooltipPadding.Large"
      class="nav-page-link"
      routerLinkActive
    >
      <i
        [class.icon-home-fill]="dashboardV2Active.isActive"
        [class.icon-home]="!dashboardV2Active.isActive"
        class="icon"
      ></i>
    </a>
  }

  @if (permissions?.CompanyConnections_View || permissions?.CompanyInvitations_View) {
    <a
      #networkActive="routerLinkActive"
      [ccTooltip]="'nav.network' | translate"
      [class.active]="networkActive.isActive"
      [routerLink]="['/network']"
      [tooltipArrow]="false"
      [tooltipOffset]="4"
      [tooltipPosition]="TooltipPosition.Right"
      [tooltipSize]="TooltipPadding.Large"
      [appFocusable]
      class="nav-page-link"
      id="networkNavItem"
      routerLinkActive
    >
      <i
        [class.icon-network-fill]="networkActive.isActive"
        [class.icon-network]="!networkActive.isActive"
        class="icon"
      ></i>
    </a>
  }

  @if (permissions?.ConversationV2_Read) {
    <a
      #conversationsV4Active="routerLinkActive"
      [ccTooltip]="'nav.conversations-v4' | translate"
      [class.active]="conversationsV4Active.isActive"
      [routerLink]="['/', CONVERSATIONS_BASE_URL]"
      [tooltipArrow]="false"
      [tooltipOffset]="4"
      [tooltipPosition]="TooltipPosition.Right"
      [tooltipSize]="TooltipPadding.Large"
      [appFocusable]
      class="nav-page-link"
      id="conversationsV4NavItem"
      routerLinkActive
    >
      <i
        [class.icon-convos-fill]="conversationsV4Active.isActive"
        [class.icon-convos]="!conversationsV4Active.isActive"
        class="icon"
      ></i>

      @if (unreadConversations(); as unreadConversations) {
        @if (unreadConversations > 0) {
          <cc-nav-counter-chip class="cc-nav-counter-chip">
            {{ unreadConversations | ccLimitNumber: 2 }}
          </cc-nav-counter-chip>
        }
      }
    </a>
  }

  @if (permissions?.Task_ViewAssigned) {
    <a
      #tasksV2Active="routerLinkActive"
      [ccTooltip]="'nav.tasks' | translate"
      [class.active]="tasksV2Active.isActive"
      [routerLink]="['/', TASKS_BASE_URL]"
      [tooltipArrow]="false"
      [tooltipOffset]="4"
      [tooltipPosition]="TooltipPosition.Right"
      [tooltipSize]="TooltipPadding.Large"
      [appFocusable]
      class="nav-page-link"
      routerLinkActive
    >
      <i
        [class.icon-tasks-fill]="tasksV2Active.isActive"
        [class.icon-tasks]="!tasksV2Active.isActive"
        class="icon"
      ></i>

      @if (incompleteTasks(); as incompleteTasks) {
        @if (incompleteTasks > 0) {
          <cc-nav-counter-chip class="cc-nav-counter-chip">
            {{ incompleteTasks | ccLimitNumber: 2 }}
          </cc-nav-counter-chip>
        }
      }
    </a>
  }

  @if (permissions?.Campaign_View) {
    <a
      #campaignsActive="routerLinkActive"
      [ccTooltip]="'nav.campaigns' | translate"
      [class.active]="campaignsActive.isActive"
      [routerLink]="['/', CAMPAIGNS_BASE_URL]"
      [tooltipArrow]="false"
      [tooltipOffset]="4"
      [tooltipPosition]="TooltipPosition.Right"
      [tooltipSize]="TooltipPadding.Large"
      [appFocusable]
      class="nav-page-link"
      routerLinkActive
    >
      <i
        [class.icon-campaigns-fill]="campaignsActive.isActive"
        [class.icon-campaigns]="!campaignsActive.isActive"
        class="icon"
      ></i>
    </a>
  }

  @if (permissions?.Workflow_View) {
    <a
      #workflowsActive="routerLinkActive"
      [ccTooltip]="'nav.workflows' | translate"
      [class.active]="workflowsActive.isActive"
      [routerLink]="['/', WORKFLOWS_BASE_URL]"
      [tooltipArrow]="false"
      [tooltipOffset]="4"
      [tooltipPosition]="TooltipPosition.Right"
      [tooltipSize]="TooltipPadding.Large"
      [appFocusable]
      class="nav-page-link"
      routerLinkActive
    >
      <i
        [class.icon-workflow-fill]="workflowsActive.isActive"
        [class.icon-workflow]="!workflowsActive.isActive"
        class="icon"
      ></i>
    </a>
  }

  @if (permissions?.User_ViewFiles || permissions?.Company_ViewFiles) {
    <a
      #filesActive="routerLinkActive"
      [ccTooltip]="'nav.files' | translate"
      [class.active]="filesActive.isActive"
      [routerLink]="['/files']"
      [tooltipArrow]="false"
      [tooltipOffset]="4"
      [tooltipPosition]="TooltipPosition.Right"
      [tooltipSize]="TooltipPadding.Large"
      [appFocusable]
      class="nav-page-link"
      routerLinkActive
    >
      <i
        [class.icon-files-fill]="filesActive.isActive"
        [class.icon-files]="!filesActive.isActive"
        class="icon"
      ></i>
    </a>
  }

  @if (permissions?.Report_View) {
    <a
      #reportsActive="routerLinkActive"
      [ccTooltip]="'nav.reporting' | translate"
      [class.active]="reportsActive.isActive"
      [routerLink]="['/reporting']"
      [tooltipArrow]="false"
      [tooltipOffset]="4"
      [tooltipPosition]="TooltipPosition.Right"
      [tooltipSize]="TooltipPadding.Large"
      [appFocusable]
      class="nav-page-link"
      routerLinkActive
    >
      <i
        [class.icon-reports-fill]="reportsActive.isActive"
        [class.icon-reports]="!reportsActive.isActive"
        class="icon"
      ></i>
    </a>
  }

  @if (permissions?.['Product_NavMenuViewV2']) {
    <a
      #productsV2Active="routerLinkActive"
      [ccTooltip]="'nav.products-v2' | translate"
      [class.active]="productsV2Active.isActive"
      [routerLink]="['/', PRODUCTS_BASE_URL]"
      [tooltipArrow]="false"
      [tooltipOffset]="4"
      [tooltipPosition]="TooltipPosition.Right"
      [tooltipSize]="TooltipPadding.Large"
      [appFocusable]
      class="nav-page-link"
      routerLinkActive
    >
      <i
        [class.icon-products-fill]="productsV2Active.isActive"
        [class.icon-products]="!productsV2Active.isActive"
        class="icon"
      ></i>
    </a>
  }

  @if (permissions?.Product_NavMenuView) {
    <a
      #productsActive="routerLinkActive"
      [ccTooltip]="'nav.products' | translate"
      [class.active]="productsActive.isActive"
      [routerLink]="['/products-old']"
      [tooltipArrow]="false"
      [tooltipOffset]="4"
      [tooltipPosition]="TooltipPosition.Right"
      [tooltipSize]="TooltipPadding.Large"
      [appFocusable]
      class="nav-page-link"
      routerLinkActive
    >
      <i
        [class.icon-products-fill]="productsActive.isActive"
        [class.icon-products]="!productsActive.isActive"
        class="icon"
      ></i>
    </a>
  }

  @if (permissions?.POC_PurchaseOrders) {
    <a
      #purchaseOrdersActive="routerLinkActive"
      [ccTooltip]="'nav.purchase-orders' | translate"
      [class.active]="purchaseOrdersActive.isActive"
      [routerLink]="['/', PO_BASE_URL]"
      [tooltipArrow]="false"
      [tooltipOffset]="4"
      [tooltipPosition]="TooltipPosition.Right"
      [tooltipSize]="TooltipPadding.Large"
      [appFocusable]
      class="nav-page-link"
      routerLinkActive
    >
      <i
        [class.icon-po-fill]="purchaseOrdersActive.isActive"
        [class.icon-po]="!purchaseOrdersActive.isActive"
        class="icon"
      ></i>
    </a>
  }

  @if (permissions.Dct_CompanyMatching) {
    <a
      #companyMatchingActive="routerLinkActive"
      [class.active]="companyMatchingActive.isActive"
      [routerLink]="['/companyMatching']"
      [appFocusable]
      class="nav-page-link"
      routerLinkActive
    >
      <i
        [style.color]="companyMatchingActive.isActive ? '#1EA2E2' : 'auto'"
        class="icon icon-company-matching"
      ></i>
    </a>
  }

  @if (permissions.Dct_CompanyMatching) {
    <a
      #companyApprovalActive="routerLinkActive"
      [class.active]="companyApprovalActive.isActive"
      [routerLink]="['/companyApproval']"
      [appFocusable]
      class="nav-page-link"
      routerLinkActive
    >
      <i
        [style.color]="companyApprovalActive.isActive ? '#1EA2E2' : 'auto'"
        class="icon icon-company-matching"
      ></i>
    </a>
  }

  @if (permissions.Dct_CompanyAccessRequest_View) {
    <a
      #companyAccessRequestActive="routerLinkActive"
      [class.active]="companyAccessRequestActive.isActive"
      [routerLink]="['/companyAccessRequests']"
      [appFocusable]
      class="nav-page-link"
      routerLinkActive
    >
      <i
        [style.color]="companyAccessRequestActive.isActive ? '#1EA2E2' : 'auto'"
        class="icon icon-company-matching"
      ></i>
    </a>
  }

  @if (permissions.AuditTrail_View) {
    <a
      #auditTrailActive="routerLinkActive"
      [ccTooltip]="'nav.auditTrail' | translate"
      [class.active]="auditTrailActive.isActive"
      [routerLink]="['/', AUDIT_TRAIL_BASE_URL]"
      [tooltipArrow]="false"
      [tooltipOffset]="4"
      [tooltipPosition]="TooltipPosition.Right"
      [tooltipSize]="TooltipPadding.Large"
      [appFocusable]
      class="nav-page-link"
      routerLinkActive
    >
      <i
        [style.color]="auditTrailActive.isActive ? '#1EA2E2' : 'auto'"
        class="icon icon-company-matching"
      ></i>
    </a>
  }
</div>
