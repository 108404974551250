import { Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslatePipe } from '@ngx-translate/core';

import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { LocalStorageService } from '@core/services/persistance.service';

@Component({
  selector: 'oops-page',
  templateUrl: './oops-page.component.html',
  styleUrls: ['./oops-page.component.scss'],
  imports: [AssetSrcDirective, TranslatePipe],
})
export class OopsPageComponent implements OnInit {
  private readonly router = inject(Router);
  private readonly lsService = inject(LocalStorageService);

  public ngOnInit(): void {
    const errors = this.lsService.get('recentErrors');
    if (errors?.length) {
      errors.forEach((err) => {
        console.error(err);
      });
    }
  }

  public redirectToDashboard(): void {
    this.router.navigate(['dashboard-old']);
  }

  public goToPreviousPage(): void {
    window.history.back();
  }
}
