<div class="modal-header">
  <button
    [appFocusable]
    type="button"
    class="close-btn"
    aria-label="Close"
    (click)="activeModal.close()"
  >
    <img
      appAssetSrc="assets/svg/common/cross.svg"
      alt="{{ 'common.buttons.close' | translate }}"
    />
  </button>
</div>

<div class="modal-body">
  <div class="tfa-modal-content">
    <img
      class="tfa-modal-illustration"
      appAssetSrc="assets/svg/settings/phone-confirmation.svg"
    />

    <h1>{{ 'tfaModal.2factorAuth' | translate }}</h1>

    <div class="tfa-modal-text">
      <p>{{ 'tfaModal.thanks' | translate }}</p>
      <p>{{ 'tfaModal.authCodeSent' | translate }}</p>
      <p class="f-bold">{{ payload().destination }}</p>
    </div>

    <form
      autocomplete="off"
      [formGroup]="tfaForm"
      #ngForm="ngForm"
      (ngSubmit)="submitCode()"
    >
      <div
        class="form-group"
        [ngClass]="{
          'has-error': ngForm.submitted && tfaForm.get('code').invalid,
        }"
      >
        <label
          class="required"
          for="code"
        >
          {{ 'tfaModal.controls.authCode.label' | translate }}
        </label>

        <input
          ngbAutofocus
          autocomplete="one-time-code"
          mask="000000"
          maxlength="6"
          formControlName="code"
          id="code"
          [placeholder]="'tfaModal.controls.authCode.placeholder' | translate"
        />

        <form-error-messages
          [showErrors]="ngForm.submitted"
          [control]="tfaForm.get('code')"
        />
      </div>

      <div class="tfa-modal-actions">
        <button
          type="button"
          (click)="resendCode()"
          [disabled]="isLoading() || canResendInSeconds() > 0"
          class="btn secondary-btn"
        >
          {{ 'tfaModal.buttons.resendCode' | translate }}

          @if (canResendInSeconds() > 0) {
            ({{ canResendInSeconds() }}s)
          }
        </button>

        <button
          type="submit"
          [appBtnLoading]="isLoading()"
          [disabled]="ngForm.submitted && tfaForm.invalid"
          class="btn btn-primary submit-btn"
        >
          {{ 'tfaModal.buttons.secureSignIn' | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
