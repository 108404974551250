@if (isLoaded) {
  <div class="auth-content">
    <div class="auth-page-header">
      <img
        alt="logo"
        appAssetSrc="assets/svg/common/logo.svg"
      />
    </div>
    @if (showLoader) {
      <loader></loader>
    }
    @if (!showLoader) {
      <!-- Company search typeahead -->
      @if (currentStep === steps.companySearch) {
        <form
          #searchForm="ngForm"
          (ngSubmit)="submitCompanyName()"
          [formGroup]="companySearchForm"
          autocomplete="off"
        >
          <h1>{{ 'companySelection.welcome' | translate }}</h1>
          <h3>{{ 'companySelection.letsFinish' | translate }}</h3>
          <h3>{{ 'companySelection.tellAboutCompany' | translate }}</h3>
          <div
            [ngClass]="{
              'has-error': searchForm.submitted && companySearchForm.get('companyName').invalid,
            }"
            class="form-group"
          >
            <label
              class="required"
              for="companyName"
            >
              {{ 'companySelection.controls.companyName.label' | translate }}
            </label>
            <div class="company-selection_search-container">
              <company-search
                #companySearch
                (matchedCompaniesChanged)="onMatchesChanged($event)"
                (selectionChanged)="onSelectionChanged($event)"
                [includeDeferred]="false"
                [preloadedMatches]="$any(matchedCompanies)"
                [preselectedCompany]="$any(selectedCompany)"
                formControlName="companyName"
              />
            </div>
            <form-error-messages
              [control]="companySearchForm.get('companyName')"
              [showErrors]="searchForm.submitted"
            />
          </div>
          <div class="company-selection_form-buttons">
            <button
              [disabled]="isSearching || (searchForm.submitted && companySearchForm.invalid)"
              class="btn btn-primary submit-btn"
              type="submit"
            >
              {{ 'common.buttons.continue' | translate }}
            </button>
          </div>
        </form>
      }
      <!--  -->
      <!-- Company details when found matching company -->
      @if (currentStep === steps.companyDetails) {
        <div class="company-selection_centered-content">
          <h1>{{ 'companySelection.isThisYourCompany' | translate }}</h1>
          <h3>{{ 'companySelection.heresSomeInformation' | translate }}</h3>
          <div class="company-selection_company-details">
            <company-info
              [compactMode]="true"
              [company]="selectedCompany"
            />
          </div>
          <div class="company-selection_form-buttons">
            <div class="buttons-group">
              <button
                (click)="openCompanySearch()"
                [appBtnLoading]="isLoading"
                class="btn btn-primary decline-btn"
              >
                {{ 'companySelection.buttons.notMyCompany' | translate }}
              </button>
              <button
                (click)="confirmSelection()"
                [appBtnLoading]="isLoading"
                class="btn btn-primary submit-btn"
              >
                {{ 'common.buttons.confirmSelection' | translate }}
              </button>
            </div>
          </div>
        </div>
      }
      <!--  -->
      <!-- Company details when user already has company (was invited to signup) -->
      @if (currentStep === steps.userHasCompany) {
        <div class="company-selection_centered-content">
          <h1>{{ 'companySelection.foundYourCompany' | translate }}</h1>
          <div class="company-selection_title-text">
            {{ 'companySelection.foundYourCompanyText' | translate }}
          </div>
          <div class="company-selection_company-details">
            <company-info
              [compactMode]="true"
              [company]="selectedCompany"
            />
          </div>
          <div class="company-selection_form-buttons">
            <div class="buttons-group">
              <button
                (click)="confirmSelected()"
                [appBtnLoading]="isLoading"
                class="btn btn-primary submit-btn"
              >
                {{ 'common.buttons.continue' | translate }}
              </button>
            </div>
          </div>
        </div>
      }
      <!--  -->
      <!-- company creation form -->
      @if (currentStep === steps.companyCreation) {
        <form
          #ngForm="ngForm"
          (ngSubmit)="checkAddress()"
          [formGroup]="companyForm"
          autocomplete="off"
        >
          <h1>{{ 'companySelection.didntSeeCompany' | translate }}</h1>
          <h3>{{ 'companySelection.tellAboutCompany' | translate }}</h3>
          <div
            [ngClass]="{
              'has-error': ngForm.submitted && companyForm.get('companyName').invalid,
            }"
            class="form-group"
          >
            <label
              class="required"
              for="companyName"
            >
              {{ 'companySelection.controls.companyName.label' | translate }}
            </label>
            <input
              formControlName="companyName"
              id="companyName"
              placeholder="{{ 'companySelection.controls.companyName.placeholder' | translate }}"
              type="text"
            />
            <form-error-messages
              [control]="companyForm.get('companyName')"
              [showErrors]="ngForm.submitted"
            />
          </div>
          <div
            [ngClass]="{
              'has-error': ngForm.submitted && companyForm.get('address').invalid,
            }"
            class="form-group"
          >
            <address-input
              [showErrors]="ngForm.submitted"
              class="company-select_address-input"
              formControlName="address"
              id="address"
              type="text"
            />
          </div>
          <div
            [ngClass]="{
              'has-error': ngForm.submitted && companyForm.get('website').invalid,
            }"
            class="form-group"
          >
            <label
              class="required"
              for="website"
            >
              {{ 'companySelection.controls.website.label' | translate }}
            </label>
            <input
              formControlName="website"
              id="website"
              placeholder="{{ 'companySelection.controls.website.placeholder' | translate }}"
              type="text"
            />
            <form-error-messages
              [control]="companyForm.get('website')"
              [showErrors]="ngForm.submitted"
            />
          </div>
          <div class="company-selection_form-buttons">
            <button
              [appBtnLoading]="isLoading"
              [disabled]="ngForm.submitted && companyForm.invalid"
              class="btn btn-primary submit-btn"
              type="submit"
            >
              {{ 'common.buttons.continue' | translate }}
            </button>
            @if (isBackBtnShown) {
              <button
                (click)="openCompanySearch()"
                class="btn btn-link"
              >
                {{ 'companySelection.buttons.goBack' | translate }}
              </button>
            }
          </div>
        </form>
      }
      <!--  -->
      <!-- Choosing addresses matched by smarty streets -->
      @if (currentStep === steps.addressSelection) {
        <div class="company-selection_centered-content">
          <div class="company-selection_address-selection">
            <h1>{{ 'companyCreation.isAddressCorrect' | translate }}</h1>
            <div>{{ 'companyCreation.isAddressCorrectSubMsg' | translate }}</div>
            <address-selection
              [(ngModel)]="selectedAddress"
              [enteredAddress]="enteredAddress"
              [suggestedAddress]="matchedAddress"
            />
            <div class="company-selection_form-buttons">
              <button
                (click)="finishAddressSelection()"
                [appBtnLoading]="isLoading"
                class="btn btn-primary submit-btn"
                type="submit"
              >
                {{ 'common.buttons.continue' | translate }}
              </button>
              <button
                (click)="currentStep = steps.companyCreation"
                [disabled]="isLoading"
                class="btn btn-link"
              >
                {{ 'companySelection.buttons.goBack' | translate }}
              </button>
            </div>
          </div>
        </div>
      }
      <!--  -->
      <!-- Address Not Found -->
      @if (currentStep === steps.addressInvalid) {
        <div class="company-selection_centered-content">
          <div class="company-selection_address-selection">
            <h1>{{ 'companyCreation.addressNotFound' | translate }}</h1>
            <div>{{ 'companyCreation.addressNotFoundSubMsg' | translate }}</div>
            <address-selection
              [(ngModel)]="selectedAddress"
              [enteredAddress]="enteredAddress"
            />
            <div class="company-selection_form-buttons">
              <button
                (click)="currentStep = steps.companyCreation"
                [appBtnLoading]="isLoading"
                class="btn btn-primary submit-btn"
                type="submit"
              >
                {{ 'companySelection.buttons.goBack' | translate }}
              </button>
            </div>
          </div>
        </div>
      }
      <!--  -->
    }
  </div>
}
