import {
  HttpBackend,
  HttpClient,
  provideHttpClient,
  withInterceptors,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { importProvidersFrom, inject, provideAppInitializer } from '@angular/core';
import { bootstrapApplication, REMOVE_STYLES_ON_COMPONENT_DESTROY } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  PreloadAllModules,
  provideRouter,
  withComponentInputBinding,
  withPreloading,
  withRouterConfig,
} from '@angular/router';
import { provideTranslateService, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { withNgxsReduxDevtoolsPlugin } from '@ngxs/devtools-plugin';
import { RouterStateSerializer, withNgxsRouterPlugin } from '@ngxs/router-plugin';
import { withNgxsStoragePlugin } from '@ngxs/storage-plugin';
import { provideStore } from '@ngxs/store';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { provideScrollbarOptions } from 'ngx-scrollbar';
import { v4 as uuidv4 } from 'uuid';

import { AppComponent } from '@clover/app.component';
import { appRoutes } from '@clover/app.routes';
import { AuthModule } from '@clover/auth/auth.module';
import { apiUrlInterceptor } from '@clover/core/interceptors/api-url.interceptor';
import { ConfigService } from '@clover/core/services/config.service';
import { CustomRouterStateSerializer } from '@clover/custom-router-state-serializer';
import { CoreModule } from '@core/core.module';
import { DOCUMENT, NAVIGATOR, WINDOW } from '@core/helpers/global-objects';
import { environment } from 'environments/environment';

function translationsLoaderFactory(handler: HttpBackend): TranslateHttpLoader {
  // We create a custom HttpClient here to bypass interceptors like apiUrlInterceptor,
  // which are unnecessary in this case since we are just loading a local asset.
  const httpClient = new HttpClient(handler);

  return new TranslateHttpLoader(httpClient, './assets/i18n/', `.json?v=${uuidv4()}`);
}

bootstrapApplication(AppComponent, {
  providers: [
    // Core
    provideRouter(
      appRoutes,
      withPreloading(PreloadAllModules),
      withRouterConfig({
        onSameUrlNavigation: 'reload',
      }),
      withComponentInputBinding(),
    ),
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi(), withInterceptors([apiUrlInterceptor])),
    provideAppInitializer(async () => {
      return await inject(ConfigService).load();
    }),
    // {
    //   provide: ErrorHandler,
    //   useClass: CustomErrorHandler,
    // },
    importProvidersFrom(CoreModule, AuthModule),

    // Store
    provideStore(
      [],
      {
        developmentMode: !environment.production,
        selectorOptions: {
          suppressErrors: true,
        },
      },
      withNgxsRouterPlugin(),
      withNgxsReduxDevtoolsPlugin({
        disabled: environment.production,
      }),
      withNgxsStoragePlugin({
        namespace: 'clover',
        keys: ['assistant'],
      }),
    ),
    { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },

    // Global objects
    { provide: DOCUMENT, useValue: document },
    { provide: WINDOW, useValue: window },
    { provide: NAVIGATOR, useValue: navigator },

    // Translations
    provideTranslateService({
      loader: {
        provide: TranslateLoader,
        useFactory: translationsLoaderFactory,
        deps: [HttpBackend],
      },
    }),

    // Miscellaneous
    provideEnvironmentNgxMask(),
    provideScrollbarOptions({
      visibility: 'hover',
      appearance: 'compact',
    }),

    // "ccAutoAnimate" directive workaround
    // By default Angular removes styles of the component from the DOM when it is destroyed.
    // Because of this, elements that use the ccAutoAnimate directive lose their styles for a moment when playing the "leave" animation.
    {
      provide: REMOVE_STYLES_ON_COMPONENT_DESTROY,
      useValue: false,
    },
  ],
}).catch((error) => {
  console.error(error);
});
