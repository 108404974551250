@if (isLoaded) {
  <div class="auth-content">
    <div class="auth-page-header">
      <img
        alt="logo"
        appAssetSrc="assets/svg/common/logo.svg"
      />
    </div>
    <div class="profile-setup-container">
      <form
        (ngSubmit)="continue()"
        [formGroup]="profileForm"
        autocomplete="off"
      >
        @if (!isProfileAlreadyComplete) {
          <h1>{{ 'profileSetup.setUpProfile' | translate }}</h1>
          <h3>{{ 'profileSetup.tellUsAboutYourself' | translate }}</h3>
        }
        @if (isProfileAlreadyComplete) {
          <h1>{{ 'profileSetup.completeProfile' | translate }}</h1>
          <h3>{{ 'profileSetup.profileAlreadyCompleted' | translate }}</h3>
        }
        <div
          [ngClass]="{ 'has-error': formSubmitted && !logo }"
          class="form-group"
        >
          <label class="required">
            {{ 'profileSetup.controls.photoUploader.label' | translate }}
          </label>
          <image-uploader
            #imageUploader
            (uploadedImage)="onImageChange($event)"
            placeholderText="profileSetup.dragPhoto"
            placeholderText2="profileSetup.makeYourselfRecognizable"
          />
          @if (formSubmitted && !logo) {
            <div class="form-control-error">
              {{ 'profileSetup.controls.photoUploader.photoRequired' | translate }}
            </div>
          }
        </div>
        @if (!isProfileAlreadyComplete) {
          <div
            [ngClass]="{
              'has-error': formSubmitted && profileForm.get('title').invalid,
            }"
            class="form-group"
          >
            <label
              class="required"
              for="title"
            >
              {{ 'profileSetup.controls.title.label' | translate }}
            </label>
            <input
              formControlName="title"
              id="title"
              placeholder="{{ 'profileSetup.controls.title.placeholder' | translate }}"
              type="text"
            />
            <form-error-messages
              [control]="profileForm.get('title')"
              [showErrors]="formSubmitted"
            />
          </div>

          <!-- Fax number -->
          <div
            class="fax-number-container form-group"
            formGroupName="fax"
          >
            <!-- Fax number -->
            <div
              class="form-row fax-number-container__number"
              formGroupName="faxNumber"
            >
              <div
                class="form-group country-code-control"
                [ngClass]="{
                  'has-error': formSubmitted && profileForm.get('fax.faxNumber.countryCode').invalid,
                }"
              >
                <label [class.required]="!profileForm.get('fax.noFaxNumberAvailable').value">
                  {{ 'signUp.controls.countryCode.label' | translate }}
                </label>
                <app-select
                  id="countryCode"
                  placeholder="common.buttons.select"
                  formControlName="countryCode"
                  [options]="countryCodes()"
                />
                <form-error-messages
                  [showErrors]="formSubmitted"
                  [control]="profileForm.get('fax.faxNumber.countryCode')"
                />
              </div>
              <div
                class="form-group"
                [ngClass]="{
                  'has-error': formSubmitted && profileForm.get('fax.faxNumber.number').invalid,
                }"
              >
                <label
                  [class.required]="!profileForm.get('fax.noFaxNumberAvailable').value"
                  for="phoneNumber"
                >
                  {{ 'profileSettings.controls.fax.label' | translate }}
                </label>
                <input
                  autocomplete="false"
                  type="text"
                  formControlName="number"
                  id="faxNumber"
                  placeholder="{{ 'profileSettings.controls.fax.placeholder' | translate }}"
                  mask="000-000-00000"
                  [validation]="false"
                />
                <form-error-messages
                  [showErrors]="formSubmitted"
                  [control]="profileForm.get('fax.faxNumber.number')"
                />
              </div>
            </div>

            <!-- Fax number toggle -->
            <div class="form-row">
              <div
                class="form-group"
                [ngClass]="{
                  'has-error': formSubmitted && profileForm.get('fax.noFaxNumberAvailable').invalid,
                }"
              >
                <div class="fax-number-container__toggle">
                  <app-toggle
                    id="hasFaxNumber"
                    formControlName="noFaxNumberAvailable"
                  />
                  <label>
                    {{ 'profileSettings.controls.fax.iDontHaveFax' | translate }}
                  </label>
                </div>
                <form-error-messages
                  [showErrors]="formSubmitted"
                  [control]="profileForm.get('fax.noFaxNumberAvailable')"
                />
              </div>
            </div>
          </div>

          <div class="form-group profile-setup_roles">
            <label>
              <span>{{ 'profileSetup.controls.role.label' | translate }}</span>
              <app-tooltip [contentTemplate]="tooltip">
                <div class="tooltip-icon"></div>
              </app-tooltip>
              <ng-template #tooltip>
                <div>{{ 'profileSetup.controls.role.tooltip' | translate }}</div>
              </ng-template>
            </label>
            <communication-roles-selector
              [compactMode]="true"
              [roles]="communicationRoles"
              formControlName="communicationRoles"
            />
          </div>
        }
        @if (!isProfileAlreadyComplete) {
          <button
            [appBtnLoading]="isLoading"
            [disabled]="formSubmitted && (profileForm.invalid || !logo)"
            class="btn btn-primary submit-btn"
            type="submit"
          >
            {{ 'common.buttons.continue' | translate }}
          </button>
        }
        @if (isProfileAlreadyComplete) {
          <button
            (click)="completeSignup()"
            [appBtnLoading]="isLoading"
            [disabled]="!logo"
            class="btn btn-primary submit-btn"
          >
            {{ 'profileSetup.buttons.completeSignup' | translate }}
          </button>
        }
      </form>
    </div>
  </div>
}
