@if (mobileInterstitialType !== MobileInterstitialType.None && isAuthenticated) {
  <cc-mobile-interstitial
    (dismiss)="dismissMobileInterstitial()"
    [type]="mobileInterstitialType"
  />
}

@if ((isImpersonating || isRepresentationMode) && impersonatedUser) {
  <div class="impersonation-notification">
    <div>
      <img appAssetSrc="assets/svg/header/impersonation.svg" />
      @if (!isRepresentationMode) {
        <div
          innerHtml="{{
            'impersonation.youAreImpersonating'
              | translate
                : {
                    person: impersonatedUser?.firstName + ' ' + impersonatedUser?.lastName,
                    company: impersonatedCompany?.name,
                  }
          }}"
        ></div>
      }
      @if (isRepresentationMode) {
        <div
          innerHtml="{{
            'impersonation.companyRepresentationMode' | translate: { company: impersonatedCompany?.name }
          }}"
        ></div>
      }
    </div>
    <button
      (click)="exitImpersonation()"
      [appFocusable]
      class="btn btn-link"
    >
      {{
        (isRepresentationMode ? 'impersonation.buttons.exitRepresentationMode' : 'impersonation.buttons.exit')
          | translate
      }}
    </button>
  </div>
}

@if (showFreeTrialNotification) {
  <div
    [ngClass]="
      'billing-notification ' + expireStatus + (isImpersonating || isRepresentationMode ? ' impersonation' : '')
    "
  >
    <div [ngClass]="'billing-notification__text ' + expireStatus">
      {{
        (subscriptionExpired ? 'billing.freeTrialExpired' : 'billing.freeTrialEnd') | translate: { day: daysToExpire }
      }}
    </div>
    <div>
      <button
        (click)="notificationClicked()"
        [ngClass]="'billing-notification__button ' + expireStatus"
        type="button"
      >
        {{ (subscriptionExpired ? 'billing.renewNow' : 'billing.chooseAPlan') | translate }}
      </button>
      <button
        (click)="dismissSubscriptionlNotification()"
        [appFocusable]
        aria-label="Close"
        class="close-btn"
        type="button"
      >
        <img
          alt="{{ 'common.buttons.close' | translate }}"
          appAssetSrc="assets/svg/common/cross-black.svg"
        />
      </button>
    </div>
  </div>
}

<nz-layout class="app-body">
  @if (isNavVisible()) {
    <nz-sider
      class="app-body__nav"
      nzWidth="56"
    >
      <app-nav />
    </nz-sider>
  }

  <nz-layout class="app-body__content">
    @if (isHeaderVisible()) {
      <nz-header class="app-body__content-header">
        <app-header
          [showLogo]="!isNavVisible"
          [forceAlternativeHeader]="isChildRouteLoading()"
        />
      </nz-header>
    }

    <nz-content class="app-body__content-body">
      @if (isChildRouteLoading()) {
        <div
          @loaderFade
          class="app-loader-overlay"
        >
          <div class="app-loader-overlay__content">
            <cc-loader class="app-loader-overlay__content-icon" />
          </div>
        </div>
      }

      <div class="app-body__content-body-inner">
        <router-outlet />
      </div>
    </nz-content>
  </nz-layout>
</nz-layout>
