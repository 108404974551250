import { Component, Input, type OnInit } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';

import { AssetSrcDirective } from '@core/directives/asset-src.directive';

@Component({
  selector: 'app-auth-step-indicator',
  templateUrl: './auth-step-indicator.component.html',
  styleUrls: ['./auth-step-indicator.component.scss'],
  imports: [AssetSrcDirective, TranslatePipe],
})
export class AuthStepIndicatorComponent implements OnInit {
  @Input() currentStepId: number;

  public steps = [
    {
      id: 1,
      label: 'authStepIndicator.start',
    },
    {
      id: 2,
      label: 'authStepIndicator.interview',
    },
    {
      id: 3,
      label: 'authStepIndicator.signup',
    },
    {
      id: 4,
      label: 'authStepIndicator.companies',
    },
    {
      id: 5,
      label: 'authStepIndicator.profile',
    },
  ];

  ngOnInit(): void {}
}
