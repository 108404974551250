import { NgClass } from '@angular/common';
import { Component, type OnInit, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslatePipe } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { ButtonLoadingDirective } from '@core/directives/button-disable.directive';
import { type ServerError } from '@core/models/serverError';
import { ToastrService } from '@core/services/toastr.service';

import { FormErrorMessagesComponent } from '../../core/components/form-error-messages/form-error-messages.component';
import { AuthService } from '../auth.service';

@UntilDestroy()
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  imports: [
    AssetSrcDirective,
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    FormErrorMessagesComponent,
    ButtonLoadingDirective,
    TranslatePipe,
  ],
})
export class ForgotPasswordComponent implements OnInit {
  private readonly authService = inject(AuthService);
  private readonly router = inject(Router);
  private readonly toastr = inject(ToastrService);
  public form: UntypedFormGroup;
  public isLoading = false;

  public ngOnInit(): void {
    this.form = new UntypedFormGroup({
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
    });
  }

  public resetPass(): void {
    if (this.form.invalid) {
      return;
    }

    this.isLoading = true;
    const form = this.form.value;
    this.authService
      .sendPasswordReset(form.email)
      .pipe(
        untilDestroyed(this),
        map(() => {
          this.router.navigate(['/passwordResetSent']);
        }),
        catchError((err) => {
          err.error.errors.forEach((err: ServerError) => {
            if (!err.property) {
              this.toastr.serverError(err);
              return;
            }

            const field = this.form.get(err.property);
            if (field) {
              field.setErrors({
                ...field.errors,
                [err.errorCode]: err.errorMessage,
              });
            }
          });

          return of(err);
        }),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe();
  }
}
