<div class="auth-content">
  <div class="auth-page-header">
    <img
      alt="logo"
      appAssetSrc="assets/svg/common/logo.svg"
    />
  </div>

  @if (!isLoaded) {
    <loader></loader>
  }

  @if (isLoaded) {
    <div class="intivation-signup_content">
      <h1>{{ 'invitationSignup.title' | translate }}</h1>
      <p
        innerHtml="{{
          (isBrokerSignup ? 'invitationSignup.brokerMessage' : 'invitationSignup.message')
            | translate: { companyName: companyName }
        }}"
      ></p>
      @if (isLoaded) {
        <form
          autocomplete="chrome-off"
          [formGroup]="signupForm"
          (ngSubmit)="signup()"
        >
          <div class="form-row">
            <div
              class="form-group"
              [ngClass]="{
                'has-error': isFormSubmitted && signupForm.get('firstName').invalid,
              }"
            >
              <label
                class="required"
                for="firstName"
              >
                {{ 'signUp.controls.firstName.label' | translate }}
              </label>
              <input
                autocomplete="false"
                type="text"
                formControlName="firstName"
                id="firstName"
                placeholder="{{ 'signUp.controls.firstName.placeholder' | translate }}"
              />
              <form-error-messages
                [showErrors]="isFormSubmitted"
                [control]="signupForm.get('firstName')"
              />
            </div>
            <div
              class="form-group"
              [ngClass]="{
                'has-error': isFormSubmitted && signupForm.get('lastName').invalid,
              }"
            >
              <label
                class="required"
                for="lastName"
              >
                {{ 'signUp.controls.lastName.label' | translate }}
              </label>
              <input
                autocomplete="false"
                type="text"
                formControlName="lastName"
                id="lastName"
                placeholder="{{ 'signUp.controls.lastName.placeholder' | translate }}"
              />
              <form-error-messages
                [showErrors]="isFormSubmitted"
                [control]="signupForm.get('lastName')"
              />
            </div>
          </div>
          <div
            class="form-group signup-form_email-field"
            [ngClass]="{
              'has-error': isFormSubmitted && signupForm.get('username').invalid,
            }"
          >
            <label
              class="required"
              for="email"
            >
              {{ (isBrokerSignup ? 'invitationSignup.brokerageEmail' : 'signUp.controls.email.label') | translate }}
            </label>
            <input
              autocomplete="chrome-off"
              type="email"
              formControlName="username"
              id="email"
              placeholder="{{ 'signUp.controls.email.placeholder' | translate }}"
            />
            <form-error-messages
              [showErrors]="isFormSubmitted"
              [customErrors]="{
                email_contains_free_domain: 'signUp.errors.email_contains_free_domain',
              }"
              [control]="signupForm.get('username')"
            />
          </div>
          <div class="form-row">
            <div
              class="form-group country-code-control"
              [ngClass]="{
                'has-error': isFormSubmitted && signupForm.get('countryCode').invalid,
              }"
            >
              <label
                class="required"
                for="countryCode"
              >
                {{ 'signUp.controls.countryCode.label' | translate }}
              </label>
              <app-select
                id="countryCode"
                placeholder="common.buttons.select"
                formControlName="countryCode"
                [options]="countryCodes"
              />
              <form-error-messages
                [showErrors]="isFormSubmitted"
                [control]="signupForm.get('countryCode')"
              />
            </div>
            <div
              class="form-group"
              [ngClass]="{
                'has-error': isFormSubmitted && signupForm.get('phoneNumber').invalid,
              }"
            >
              <label
                class="required"
                for="phoneNumber"
              >
                {{ 'signUp.controls.phoneNumber.label' | translate }}
              </label>
              <input
                autocomplete="chrome-off"
                type="text"
                formControlName="phoneNumber"
                id="phoneNumber"
                placeholder="{{ 'signUp.controls.phoneNumber.placeholder' | translate }}"
                mask="000-000-00000"
                [validation]="false"
              />
              <form-error-messages
                [showErrors]="isFormSubmitted"
                [control]="signupForm.get('phoneNumber')"
              />
            </div>
          </div>
          <div
            class="form-group"
            [ngClass]="{
              'has-error': isFormSubmitted && signupForm.get('password').invalid,
            }"
          >
            <label class="required">
              {{ 'common.controls.password.label' | translate }}
            </label>
            <app-password-input formControlName="password" />
          </div>
          <div class="form-check signup-form_terms-of-use">
            <input
              type="checkbox"
              class="form-check-input"
              id="terms"
              formControlName="terms"
            />
            <label
              class="required"
              class="form-check-label"
              for="terms"
            >
              <span
                class="checkmark"
                [ngClass]="{ checked: signupForm.get('terms').value }"
                tabindex="0"
              ></span>
              <span>{{ 'signUp.iAcceptThe' | translate }}</span>
              <a
                href="#"
                (click)="openTerms()"
              >
                {{ 'signUp.termsOfUse' | translate }}
              </a>
            </label>
          </div>
          <button
            type="submit"
            [appBtnLoading]="isLoading"
            [disabled]="!signupForm.get('terms').value || (isFormSubmitted && signupForm.invalid)"
            class="btn btn-primary submit-btn submit-btn-wide"
          >
            {{ 'common.buttons.continue' | translate }}
          </button>
          <div class="signup-form_login-link">
            <span>{{ 'signUp.alreadyHaveAcc' | translate }}</span>
            <a [routerLink]="['/login']">
              {{ 'common.buttons.signIn' | translate }}
            </a>
          </div>
        </form>
      }
    </div>
  }
</div>
