import { Component, type OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslatePipe } from '@ngx-translate/core';

import { AssetSrcDirective } from '@core/directives/asset-src.directive';

@Component({
  selector: 'app-invitation-already-accepted-page',
  templateUrl: './invitation-already-accepted-page.component.html',
  styleUrls: ['./invitation-already-accepted-page.component.scss'],
  imports: [AssetSrcDirective, RouterLink, TranslatePipe],
})
export class InvitationAlreadyAcceptedPageComponent implements OnInit {
  ngOnInit(): void {}
}
