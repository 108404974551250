<app-button
  [type]="ButtonType.Secondary"
  [size]="ButtonSize.Small"
  #dropdown="ccDropdownTrigger"
  [ccDropdownTrigger]="createDropdown"
  [ccDropdownPositions]="{
    offsetY: 4,
    positions: ['bottom-start'],
  }"
>
  {{ 'createButton.title' | translate }}

  <i
    class="create-button-icon icon-chevronDown"
    [class.create-button-icon--open]="dropdown.isOpen()"
    slot="end-icon"
  ></i>
</app-button>

<ng-template #createDropdown>
  <cc-dropdown width="180px">
    <!-- New Task -->
    <cc-dropdown-action
      [disabled]="!permissions.Task_Assign"
      (click)="createNewTask(); dropdown.close()"
    >
      <ng-container slot="title">
        {{ 'createButton.newTask.title' | translate }}
      </ng-container>
    </cc-dropdown-action>

    <!-- New Email -->
    <cc-dropdown-action [disabled]="!permissions.ConversationV2_Read">
      <ng-container slot="title">
        {{ 'createButton.newEmail.title' | translate }}
      </ng-container>

      <cc-dropdown>
        <cc-dropdown-text>
          <ng-container slot="title">
            {{ 'createButton.newEmail.emailSender' | translate }}
          </ng-container>
        </cc-dropdown-text>

        @switch (externalAccountsLoadingStatus()) {
          @case ('loading') {
            <cc-dropdown-text>
              <ng-container slot="title">
                <span class="cc-ellipsis-animation">
                  {{ 'createButton.newEmail.loadingAccounts' | translate }}
                </span>
              </ng-container>
            </cc-dropdown-text>
          }

          @case ('loaded') {
            @for (account of externalAccounts(); track account.id) {
              <cc-dropdown-action (click)="createNewEmail(account); dropdown.close()">
                <ng-container slot="title">{{ account.name }}</ng-container>
                <ng-container slot="caption">{{ account.email }}</ng-container>
              </cc-dropdown-action>
            } @empty {
              <cc-dropdown-text>
                <ng-container slot="title">
                  {{ 'createButton.newEmail.noAccounts' | translate }}
                </ng-container>
              </cc-dropdown-text>
            }
          }

          @case ('error') {
            <cc-dropdown-text>
              <ng-container slot="title">
                {{ 'createButton.newEmail.noAccounts' | translate }}
              </ng-container>
            </cc-dropdown-text>
          }
        }
      </cc-dropdown>
    </cc-dropdown-action>
  </cc-dropdown>
</ng-template>
