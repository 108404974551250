import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslatePipe } from '@ngx-translate/core';

import { AssetSrcDirective } from '@core/directives/asset-src.directive';

@Component({
  selector: 'app-not-found-page',
  templateUrl: './not-found-page.component.html',
  styleUrls: ['./not-found-page.component.scss'],
  imports: [AssetSrcDirective, TranslatePipe],
})
export class NotFoundPageComponent {
  private readonly router = inject(Router);

  public redirectToDashboard(): void {
    this.router.navigate(['dashboard-old']);
  }
}
