import type { HttpErrorResponse } from '@angular/common/http';
import { inject, Injector } from '@angular/core';
import { ResolveFn, Router } from '@angular/router';
import { catchError, from, map, of, switchMap, tap, type Observable } from 'rxjs';

import { WINDOW } from '@clover/core/helpers/global-objects';
import type { LoginData } from '@clover/core/models/loginData';
import { ToastrService } from '@clover/core/services/toastr.service';

import { AuthService, SsoLoginErrorCode } from '../auth.service';
import { getSSORedirectUrl } from './redirect-url';

export const SSO_CODE_VERIFIER_KEY = 'clover:sso:codeVerifier';

export const ssoCallbackResolver: ResolveFn<false> = (route) => {
  const router = inject(Router);
  const injector = inject(Injector);

  // If token is present, continue using token verification
  const token = parseParamFromFragment(route.fragment, 'id_token');

  if (token) return handleTokenVerification(injector, token);

  // If code and state are present, continue using code verification
  const code = parseParamFromFragment(route.fragment, 'code');
  const state = parseParamFromFragment(route.fragment, 'state');

  if (code && state) return handleCodeVerification(injector, code, state);

  // Otherwise, cancel authentication and navigate to login
  router.navigate(['/', 'login']);
  return false;
};

function handleTokenVerification(injector: Injector, token: string): Observable<false> {
  const authService = injector.get(AuthService);
  const router = injector.get(Router);

  return authService.verifySSOToken(token).pipe(
    switchMap((loginData: LoginData) =>
      from(authService.handleSuccessfullLogin(loginData)).pipe(
        tap((loginSuccess) => !loginSuccess && router.navigate(['/', 'login'])),
      ),
    ),
    map(() => false as const),
    catchError((errorResponse) => {
      handleSSOLoginError(injector, errorResponse);
      router.navigate(['/', 'login']);

      return of(false as const);
    }),
  );
}

function handleCodeVerification(injector: Injector, code: string, state: string): Observable<false> {
  const authService = injector.get(AuthService);
  const router = injector.get(Router);
  const window = injector.get(WINDOW);

  const codeVerifier = window.sessionStorage.getItem(SSO_CODE_VERIFIER_KEY);
  if (codeVerifier) window.sessionStorage.removeItem(SSO_CODE_VERIFIER_KEY);

  const redirectUrl = getSSORedirectUrl(window.location.origin);

  return authService.verifySSOCode(code, state, redirectUrl, codeVerifier).pipe(
    switchMap((loginData: LoginData) =>
      from(authService.handleSuccessfullLogin(loginData)).pipe(
        tap((loginSuccess) => !loginSuccess && router.navigate(['/', 'login'])),
      ),
    ),
    map(() => false as const),
    catchError((errorResponse) => {
      handleSSOLoginError(injector, errorResponse);
      router.navigate(['/', 'login']);

      return of(false as const);
    }),
  );
}

function parseParamFromFragment(fragment: string, param: 'id_token' | 'code' | 'state'): string | undefined {
  const params = new URLSearchParams(fragment);
  return params.get(param) || undefined;
}

function handleSSOLoginError(injector: Injector, errorResponse: HttpErrorResponse): void {
  const errorCodes: string[] =
    errorResponse?.error?.errors?.map((error: { errorCode: string }) => error?.errorCode) || [];

  const toastr = injector.get(ToastrService);

  if (errorCodes.includes(SsoLoginErrorCode.UserNotFound)) {
    toastr.error('User not found. Please contact your administrator.');
  }

  if (errorCodes.includes(SsoLoginErrorCode.InvalidToken)) {
    toastr.error('signIn.errors.ssoInvalidToken');
  }

  if (errorCodes.includes(SsoLoginErrorCode.InvalidDomain)) {
    toastr.error('signIn.errors.ssoInvalidDomain');
  }
}
