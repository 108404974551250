import { Component, type OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslatePipe } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

import { PAGE_URL } from '@core/constants/page-url';
import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { ToastrService } from '@core/services/toastr.service';

import { AuthService } from '../../auth.service';

@UntilDestroy()
@Component({
  selector: 'app-email-confirm-token-expired',
  templateUrl: './email-confirm-token-expired.component.html',
  styleUrls: ['./email-confirm-token-expired.component.scss'],
  imports: [AssetSrcDirective, TranslatePipe],
})
export class EmailConfirmTokenExpiredComponent implements OnInit {
  private readonly auth = inject(AuthService);
  private readonly toastr = inject(ToastrService);
  private readonly router = inject(Router);
  public isLoading = false;
  public isLoaded = false;

  public ngOnInit(): void {
    if (!this.auth.emailConfirmationToken) {
      this.router.navigate([PAGE_URL.LOGIN]);
    } else {
      this.isLoaded = true;
    }
  }

  public resendEmail(event: Event): void {
    event.preventDefault();
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;
    this.auth
      .resendEmailByToken(this.auth.emailConfirmationToken)
      .pipe(
        untilDestroyed(this),
        catchError((err) => {
          this.toastr.displayServerErrors(err);
          return of(err);
        }),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe(() => {
        this.toastr.success('emailSent.messages.resendSuccess');
      });
  }
}
