import { Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslatePipe } from '@ngx-translate/core';
import { type Observable } from 'rxjs';

import { SearchInputComponent } from '@core/components/search-input/search-input.component';
import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { ButtonLoadingDirective } from '@core/directives/button-disable.directive';
import { FocusableDirective } from '@core/directives/focusable.directive';

import { LegacyLoaderComponent } from '../../components/loader/loader.component';
import { type User } from '../../models/user';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-impersonate-modal',
  templateUrl: './impersonate-modal.component.html',
  styleUrls: ['./impersonate-modal.component.scss'],
  imports: [
    FocusableDirective,
    TranslatePipe,
    SearchInputComponent,
    FormsModule,
    ButtonLoadingDirective,
    AssetSrcDirective,
    LegacyLoaderComponent,
  ],
})
export class ImpersonateModalComponent {
  private readonly userService = inject(UserService);
  public readonly activeModal = inject(NgbActiveModal);
  public searchValue: string;
  public selectedUser: User;
  public isLoading = false;
  public isSearchLoading = false;

  public userSearchFunc: (query: string, limit: number, offset: number) => Observable<{ data: any; total: number }>;
  public searchNameFormatFunc: (user: User) => string;

  constructor() {
    this.userSearchFunc = (query: string, limit: number, offset: number) => {
      return this.userService.searchUsers(query, limit, offset);
    };
    this.userSearchFunc = this.userSearchFunc.bind(this);

    this.searchNameFormatFunc = (user: User) => {
      return this.getComposedUserName(user);
    };
    this.searchNameFormatFunc = this.searchNameFormatFunc.bind(this);
  }

  public impersonate(): void {
    this.userService.impersonate(this.selectedUser).then(() => {
      this.activeModal.close();
    });
  }

  public getLogoPlaceholder(user: User): string {
    return `${user.firstName[0]}${user.lastName[0]}`.toUpperCase();
  }

  public getComposedUserName(user: User): string {
    return `${user.firstName} ${user.lastName}`;
  }

  public clearSearch(): void {
    this.selectedUser = null;
    this.searchValue = '';
  }

  public onUserSelect(user: User | null | any): void {
    if (!user) {
      this.clearSearch();
      return;
    }
    this.isLoading = true;
    this.userService
      .getUserInfo(user.id)
      .then((res) => {
        this.selectedUser = res;
      })
      .finally(() => (this.isLoading = false));
  }
}
